import React, { useContext, useEffect } from 'react'

import { AuthContext } from 'contexts/auth'
import { GetServerSideProps } from 'next'
import { config } from 'node-config-ts'
import { getDefaultStaticProps } from 'shared/withPageProps/withStaticProps'

export const getServerSideProps: GetServerSideProps<Record<string, unknown>> = async ({ req }) => {
	const hasAuthCookie = Boolean('asta-auth.session-token' in req.cookies)
	const defaultStaticProps = await getDefaultStaticProps()

	if (hasAuthCookie) {
		console.log('Logged In user, redirecting to Companion')
		return {
			redirect: {
				destination: config.services.companion.url,
				permanent: false
			}
		}
	}

	return {
		props: defaultStaticProps
	}
}

const DefaultLogInPage = () => {
	const { logIn } = useContext(AuthContext)

	useEffect(() => {
		logIn()
	}, [])

	return <></>
}

export default DefaultLogInPage
